import Hero from "../components/Hero";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Product from "../components/Product";
import Featured from "../components/Featured";
import Prod from "../components/Prod";
import Stats from "../components/Stats";
import Contact from "../components/Contact";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import HeroCar from "../images/logo/profile.png";
import Gallery from "../components/Gallery";

function Home() {
  return (
    <>
      <Hero />
      <Prod />
     <Featured />
     <Stats />

      {/* <ChooseUs /> */}
      <Banner />

      <Product />
      <Gallery />
      <Contact />
      {/* <Benefits />
      <Purchase /> */}
      <Footer /> 
      <FloatingWhatsApp
        phoneNumber="2262244985"
        accountName="Arasa"
        statusMessage="Typically replies within 1 hour"
        chatMessage	="Hello there! 🤝 How can we help?"
        placeholder	="Type a message.."
        avatar={HeroCar}
        allowEsc
        allowClickAway
        notification
        notificationSound
        buttonClassName="floating-whatsapp-button"
      />
    </>
  );
}

export default Home;
